import Vue from 'vue'
import VueRouter from 'vue-router'
// --------------------------------------------------------------------------------- //
// 認証外
import LoginPage from '../views/public/LoginPage.vue';
import ForgetPassword from '../views/public/ForgetPassword.vue';
import PasswordReset from '../views/public/PasswordReset.vue';
import EmailAuthentication from '../views/public/EmailAuthentication.vue';
import AutoLogin from '../views/public/AutoLogin.vue';
// --------------------------------------------------------------------------------- //
// 共通
import ProfileSetting from '../views/common/ProfileSetting.vue';
import ContactDetails from '../views/common/ContactDetails.vue';
import TalkRoom from '../views/common/TalkRoom.vue';
import SurveyDetails from '../views/common/SurveyDetails.vue';
import TermsOfService from '../views/common/TermsOfService.vue';
// --------------------------------------------------------------------------------- //
// 生徒・保護者
import StudentTopPage from '../views/user/StudentTopPage.vue';
import GuardianTopPage from '../views/user/GuardianTopPage.vue';

import CourseSelect from '../views/user/CourseSelect.vue';
import CourseSelectParent from '../views/user/CourseSelectParent.vue';
import StudentSelect from '../views/user/StudentSelect.vue';
import AttendanceRegist from '../views/user/AttendanceRegist.vue';
import LeavingReply from '../views/user/LeavingReply.vue';
import AttendanceFutureRegist from '../views/user/AttendanceFutureRegist.vue';
import AttendancePastView from '../views/user/AttendancePastView.vue';

// --------------------------------------------------------------------------------- //
// 教員・管理者
import TeacherTopPage from '../views/teacher/TeacherTopPage.vue';
import AttendanceConfirm from '../views/teacher/AttendanceConfirm.vue';
import LeavingConfirm from '../views/teacher/LeavingConfirm.vue';
import AttendanceSummary from '../views/teacher/AttendanceSummary.vue';

import GroupSelect from '../views/teacher/GroupSelect.vue';
import GroupMemberList from '../views/teacher/GroupMemberList.vue';
import ContactRegist from '../views/teacher/ContactRegist.vue';
import SurveyRegist from '../views/teacher/SurveyRegist.vue';
import UserList from '../views/teacher/UserList.vue';
import CourseSelectView from '../views/teacher/CourseSelectView.vue';
import AttendanceEdit from '../views/teacher/AttendanceEdit.vue';
import LeavingRegist from '../views/teacher/LeavingRegist.vue';
import ReceiptStatus from '../views/teacher/ReceiptStatus.vue';
import AnswerStatusSurvey from '../views/teacher/AnswerStatusSurvey.vue';

import ContactList from '../views/teacher/ContactList.vue';
import ContactEdit from '../views/teacher/ContactEdit.vue';
import SurveyList from '../views/teacher/SurveyList.vue';
import SurveyEdit from '../views/teacher/SurveyEdit.vue';
import SurveyChartView from '../views/teacher/SurveyChartView.vue';
import TalkRoomList from '../views/teacher/TalkRoomList.vue';
import BarcodeCheckIn from '../views/teacher/BarcodeCheckIn.vue';
// 設定--------------------------------------------------------------------------------- //
import SettingEdit from '../views/settings/SettingEdit.vue';

// 健康--------------------------------------------------------------------------------- //
import HealthRoomReception from '../views/health/HealthRoomReception.vue';
import HealthRoomReceptionRegist from '../views/health/HealthRoomReceptionRegist.vue';
import HealthRoomRecodeEdit from '../views/health/HealthRoomRecodeEdit.vue';

// --------------------------------------------------------------------------------- //
import PoGroupSelect from '../views/teacher/portfolio/PoGroupSelect.vue';
import PoHomeworkRegist from '../views/teacher/portfolio/PoHomeworkRegist.vue';
import PoTargetSelect from '../views/teacher/portfolio/PoTargetSelect.vue';
import PoHomeworkListForGroup from '../views/teacher/portfolio/PoHomeworkListForGroup.vue';
import PoUserList from '../views/teacher/portfolio/PoUserList.vue';
import PoHomeworkListForSelf from '../views/teacher/portfolio/PoHomeworkListForSelf.vue';
import PoHomeworkEdit from '../views/teacher/portfolio/PoHomeworkEdit.vue';
import PoActivityRecordListForHomework from '../views/teacher/portfolio/PoActivityRecordListForHomework.vue';
import PoActivityRecordListForStudent from '../views/teacher/portfolio/PoActivityRecordListForStudent.vue';
import PoActivityRecordTemplate from '../views/teacher/portfolio/PoActivityRecordTemplate.vue';
import PoActivityRecordTimeline from '../views/teacher/portfolio/PoActivityRecordTimeline.vue';
import PoStoryTimeline from '../views/teacher/portfolio/PoStoryTimeline.vue';
import PoStoryListForGroup from '../views/teacher/portfolio/PoStoryListForGroup.vue';

import PoStudyHistory from '../views/user/portfolio/PoStudyHistory.vue';
import PoActivityRecordRegist from '../views/user/portfolio/PoActivityRecordRegist.vue';
import PoHomeworkDetails from '../views/user/portfolio/PoHomeworkDetails.vue';
import PoStudyStoryRegist from '../views/user/portfolio/PoStudyStoryRegist.vue';

// --------------------------------------------------------------------------------- //
import GroupList from '../views/admin/GroupList.vue';
import GroupRegist from '../views/admin/GroupRegist.vue';
import GroupAttach from '../views/admin/GroupAttach.vue';
import GroupAttachTeachers from '../views/admin/GroupAttachTeachers.vue';
import GroupEdit from '../views/admin/GroupEdit.vue';

// --------------------------------------------------------------------------------- //

Vue.use(VueRouter)

const routes = [
  // 認証外
  { // ログイン画面
    path: '/',
    name: 'Login',
    component: LoginPage,
  },
  { // パスワード変更
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
  { // パスワード再設定
    path: '/password-reset/:collisionId',
    name: 'PasswordReset',
    component: PasswordReset,
    props: true
  },
  { // メールアドレス認証
    path: '/email-authentication/:collisionId',
    name: 'EmailAuthentication',
    component: EmailAuthentication,
    props: true
  },
  { // 自動ログイン(スマートフォンアプリ呼出)
    path: '/auto-login/:collisionId',
    name: 'AutoLogin',
    component: AutoLogin,
    props: true
  },
  // --------------------------------------------------------------------------------- //
  // 共通
  { // プロフィール設定
    path: '/common/profile-setting',
    name: 'ProfileSetting',
    component: ProfileSetting,
  },
  { // 連絡確認（詳細）
    path: '/common/contact-details/:messageId',
    name: 'ContactDetails',
    component: ContactDetails,
    props: true
  },
  { // アンケート回答
    path: '/common/survey-details/:responseId',
    name: 'SurveyDetails',
    component: SurveyDetails,
    props: true
  },
  { // 利用規約確認
    path: '/common/terms-of-service',
    name: 'TermsOfService',
    component: TermsOfService,
  },
  { // 個別連絡
    path: '/common/talk-room/:roomId',
    name: 'TalkRoom',
    component: TalkRoom,
    props: true
  },
  // --------------------------------------------------------------------------------- //
  { // 生徒トップページ
    path: '/user/student-top-page',
    name: 'StudentTopPage',
    component: StudentTopPage,
  },
  { // 保護者トップページ
    path: '/user/guardian-top-page',
    name: 'GuardianTopPage',
    component: GuardianTopPage,
  },
  { // コース選択トップページ
    path: '/user/course-select',
    name: 'CourseSelect',
    component: CourseSelect,
  },
  { // 学習のきろくトップページ
    path: '/user/po-study-history',
    name: 'PoStudyHistory',
    component: PoStudyHistory,
  },
  { // 活動記録の登録
    path: '/user/po-activity-record-regist',
    name: 'PoActivityRecordRegist',
    component: PoActivityRecordRegist,
    props: true
  },
  { // コース選択確認トップページ
    path: '/user/course-select-parent',
    name: 'CourseSelectParent',
    component: CourseSelectParent,
  },
  { // 被保護者選択
    path: '/user/student-select',
    name: 'StudentSelect',
    component: StudentSelect,
  },
  { // 出欠登録/健康報告
    path: '/user/attendance-regist/:id',
    name: 'AttendanceRegist',
    component: AttendanceRegist,
    props: true
  },
  { // 早退返信
    path: '/user/leaving-reply/:id',
    name: 'LeavingReply',
    component: LeavingReply,
    props: true
  },
  { // 出欠事前連絡
    path: '/user/attendance-future-regist',
    name: 'AttendanceFutureRegist',
    component: AttendanceFutureRegist,
    props: true
  },
  { // 出欠履歴確認
    path: '/user/attendance-past-view',
    name: 'AttendancePastView',
    component: AttendancePastView,
    props: true
  },

  // --------------------------------------------------------------------------------- //
  { // 教員/管理者トップページ
    path: '/teacher/teacher-top-page',
    name: 'TeacherTopPage',
    component: TeacherTopPage,
  },
  { // 出欠確認
    path: '/teacher/attendance-confirm',
    name: 'AttendanceConfirm',
    component: AttendanceConfirm,
    props: true
  },
  { // 早退確認
    path: '/teacher/leaving-confirm',
    name: 'LeavingConfirm',
    component: LeavingConfirm,
    props: true
  },
  { // 日別出欠集計確認
    path: '/teacher/attendance-summary',
    name: 'AttendanceSummary',
    component: AttendanceSummary,
  },
  // --------------------------------------------------------------------------------- //
  { // 生徒一覧グループ選択
    path: '/teacher/group-select',
    name: 'GroupSelect',
    component: GroupSelect,
  },
  { // 連絡登録
    path: '/teacher/contact-regist',
    name: 'ContactRegist',
    component: ContactRegist,
    props:true,
  },
  { // アンケート登録
    path: '/teacher/survey-regist',
    name: 'SurveyRegist',
    component: SurveyRegist,
    props:true,
  },
  { // ユーザ一覧
    path: '/teacher/user-list',
    name: 'UserList',
    component: UserList,
    props: true
  },
  { // コース選択状況確認
    path: '/teacher/course-select-view',
    name: 'CourseSelectView',
    component: CourseSelectView,
    props: true
  },
  { // 出欠訂正入力
    path: '/teacher/attendance-edit',
    name: 'AttendanceEdit',
    component: AttendanceEdit,
    props: true
  },
  { // 早退登録
    path: '/teacher/leaving-regist',
    name: 'LeavingRegist',
    component: LeavingRegist,
    props: true
  },
  // --------------------------------------------------------------------------------- //
  { // 連絡一覧
    path: '/teacher/contact-list',
    name: 'ContactList',
    component: ContactList,
  },
  { // 連絡編集
    path: '/teacher/contact-edit/:id',
    name: 'ContactEdit',
    component: ContactEdit,
    props: true
  },
  { // アンケート一覧
    path: '/teacher/survey-list',
    name: 'SurveyList',
    component: SurveyList,
  },
  { // アンケート編集
    path: '/teacher/survey-edit/:id',
    name: 'SurveyEdit',
    component: SurveyEdit,
    props: true
  },
  { // アンケート集計(グラフ表示)
    path: '/teacher/survey-chart-view',
    name: 'SurveyChartView',
    component: SurveyChartView,
    props: true
  },
  { // 個別連絡一覧
    path: '/teacher/talk-room-list',
    name: 'TalkRoomList',
    component: TalkRoomList,
  },
  { // 既読・未読確認
    path: '/teacher/receipt-status',
    name: 'ReceiptStatus',
    component: ReceiptStatus,
    props: true
  },
  { // アンケート回答状況一覧
    path: '/teacher/answer-status-survey',
    name: 'AnswerStatusSurvey',
    component: AnswerStatusSurvey,
    props: true
  },
  { // グループ所属者一覧
    path: '/teacher/group-member-list',
    name: 'GroupMemberList',
    component: GroupMemberList,
    props: true
  },
  { // バーコード受付
    path: '/teacher/barcode-check-in',
    name: 'BarcodeCheckIn',
    component: BarcodeCheckIn,
  },
  // 設定関連--------------------------------------------------------------------------------- //
  { // 設定変更
    path: '/settings/setting-edit',
    name: 'SettingEdit',
    component: SettingEdit,
  },
  // 健康関連--------------------------------------------------------------------------------- //
  { // 保健室受付
    path: '/health/health-room-reception',
    name: 'HealthRoomReception',
    component: HealthRoomReception,
  },
  { // 保健室受付(検索登録)
    path: '/health/health-room-reception-regist',
    name: 'HealthRoomReceptionRegist',
    component: HealthRoomReceptionRegist,
  },
  { // 来室記録更新・出力
    path: '/health/health-room-recode-edit',
    name: 'HealthRoomRecodeEdit',
    component: HealthRoomRecodeEdit,
  },
  // ポートフォリオ関連--------------------------------------------------------------------------------- //
  { // グループ選択
    path: '/teacher/po-group-select',
    name: 'PoGroupSelect',
    component: PoGroupSelect,
  },
  { // 課題登録
    path: '/teacher/po-homework-regist',
    name: 'PoHomeworkRegist',
    component: PoHomeworkRegist,
    props: true
  },
  { // 課題対象選択
    path: '/teacher/po-target-select',
    name: 'PoTargetSelect',
    component: PoTargetSelect,
    props: true
  },
  { // 課題一覧(グループ指定)
    path: '/teacher/po-homework-list-for-group',
    name: 'PoHomeworkListForGroup',
    component: PoHomeworkListForGroup,
    props: true
  },
  { // 生徒一覧
    path: '/teacher/po-user-list',
    name: 'PoUserList',
    component: PoUserList,
    props: true
  },
  { // 課題編集
    path: '/teacher/po-homework-edit',
    name: 'PoHomeworkEdit',
    component: PoHomeworkEdit,
    props: true
  },
  { // 課題確認
    path: '/teacher/po-homework-list-for-self',
    name: 'PoHomeworkListForSelf',
    component: PoHomeworkListForSelf,
    props: true
  },
  { // 活動記録一覧(課題)
    path: '/teacher/po-activity-record-list-for-homework',
    name: 'PoActivityRecordListForHomework',
    component: PoActivityRecordListForHomework,
    props: true
  },
  { // 活動記録一覧(生徒)
    path: '/teacher/po-activity-record-list-for-student',
    name: 'PoActivityRecordListForStudent',
    component: PoActivityRecordListForStudent,
    props: true
  },
  { // 活動記録テンプレート
    path: '/teacher/po-activity-record-template',
    name: 'PoActivityRecordTemplate',
    component: PoActivityRecordTemplate,
  },
  { // 活動記録タイムライン
    path: '/teacher/po-activity-record-timeline',
    name: 'PoActivityRecordTimeline',
    component: PoActivityRecordTimeline,
  },
  { // 学習のきろくタイムライン
    path: '/teacher/po-story-timeline',
    name: 'PoStoryTimeline',
    component: PoStoryTimeline,
  },
  { // 学習のきろく一覧
    path: '/teacher/po-story-list-for-group',
    name: 'PoStoryListForGroup',
    component: PoStoryListForGroup,
    props: true
  },
  { // 課題提出画面
    path: '/user/po-homework-details/:reportId',
    name: 'PoHomeworkDetails',
    component: PoHomeworkDetails,
    props: true
  },
  { // 学習のまとめ登録画面
    path: '/user/study-story-regist/:storyCd',
    name: 'PoStudyStoryRegist',
    component: PoStudyStoryRegist,
    props: true
  },
  // --------------------------------------------------------------------------------- //
  { // グループ一覧
    path: '/admin/group-list',
    name: 'GroupList',
    component: GroupList,
  },
  { // グループ登録
    path: '/admin/group-regist',
    name: 'GroupRegist',
    component: GroupRegist,
  },
  { // グループ割当(生徒)
    path: '/admin/group-attach',
    name: 'GroupAttach',
    component: GroupAttach,
    props: true
  },
  { // グループ割当(教師)
    path: '/admin/group-attach-teachers',
    name: 'GroupAttachTeachers',
    component: GroupAttachTeachers,
    props: true
  },
  { // グループ編集
    path: '/admin/group-edit',
    name: 'GroupEdit',
    component: GroupEdit,
    props: true
  },
]

const router = new VueRouter({
  routes
})

export default router
